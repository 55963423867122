"use client"
import Home from "@/classes/Home"
import { useEffect } from "react"

export default function HomeAnimations({ volume, cap }: { volume: number; cap: number }) {
  useEffect(() => {
    const home = new Home(volume, cap)
    home.create()
  }, [cap, volume])

  return <></>
}
